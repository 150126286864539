body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  /* width: 100vw; */
  /* overflow-x: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#fixedbutton {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 100;
  width: 40px;
}
#fixedbutton:hover {
  width: 45px;
  bottom: 0.8rem;
  right: 0.8rem;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #001224;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(68, 68, 68);
}

/* remove arrows from number input  */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.verticalCenter {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  height: "100%";
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: "100%";
}

.dark-select {
  background-color: #010e1c;
  color: #bfbfbf;
}

.dark-blue-1 {
  background-color: #001224;
  color: #bfbfbf;
}
.dark-blue-2 {
  background-color: rgba(102, 179, 255, 0.08);
  color: #bfbfbf;
}
.dark-blue-3 {
  background-color: rgba(102, 179, 255, 0.12);
  color: #d4d1d1;
}

div {
  font-size: 0.8rem;
  color: #bfbfbf;
}
a {
  color: white !important;
  /* text-decoration: none; */
}
a:hover {
  color: white !important;
  /* text-decoration: none !important; */
}

span,
button {
  color: #bfbfbf;
}

.display-none {
  display: none;
}

.notification .badge {
  position: absolute;
  top: -10px;
  right: -30px;
  padding: 3px 8px;
  border-radius: 50%;
  background: rgb(185, 141, 30);
  font-size: 0.7rem;
  color: white;
}
.notification {
  color: white;
  text-decoration: none;
  position: relative;
  display: inline-block;
}

.sidebarItem {
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  margin: 0 0 0 0;
  cursor: pointer;
  background: transparent;
  border: none;
  text-align: left;
  display: block;
  text-decoration: none;
  min-width: 100%;
  overflow: hidden;
  max-height: 2.2rem;
}
.sidebarItem:hover {
  background-color: hsl(210, 100%, 7%);
  text-decoration: none;
}
.sidebarItem-active {
  background-color: hsl(210, 100%, 7%);
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  margin: 0 0 0 0;
  cursor: pointer;
  border: none;
  text-align: left;
  display: block;
  text-decoration: none;
  min-width: 100%;
  overflow: hidden;
  max-height: 2.2rem;
}
.sidebarItem-active:hover {
  text-decoration: none;
}

.gray-btn-active {
  background-color: #4d4d4d;
  color: "#b3b3b3";
  border: 1px solid #4d4d4d;
  text-decoration: none;
}
.gray-btn-active:hover {
  background-color: #5e5d5d;
  border-color: black;
  color: "white";
  text-decoration: none;
}
.gray-btn {
  background: none;
  color: "#b3b3b3";
  border: 1px solid #4d4d4d;
  text-decoration: none;
}
.gray-btn:hover {
  background-color: #4d4d4d;
  color: "white";
  border: 1px solid #4d4d4d;
  text-decoration: none;
}

/* new toggle button */
.gray-toggle-btn {
  background-color: #1d2236;
  padding: 5px;
  border-radius: 10px;
  /* color: #b3b3b3;
  border: 1px solid #1d2236;
  text-decoration: none;
  width: 100%; */
}

.gray-toggle-btn .active {
  background-color: #272d43;
  color: #d1d1d1;
  border: 1px solid #272d43;
  text-decoration: none;
  width: 100%;
  padding: 2%;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: bold;
}
.gray-toggle-btn .active:hover {
  background-color: #272d43;
  border-color: #272d43;
  color: white;
  text-decoration: none;
  width: 100%;
  padding: 2%;
  font-size: 1rem;
  font-weight: bold;
}
.gray-toggle-btn .inactive {
  background: none;
  color: #4c5370;
  border: 1px solid #1d2236;
  text-decoration: none;
  width: 100%;
  padding: 2%;
  font-size: 1rem;
  font-weight: bold;
}
.gray-toggle-btn .inactive:hover {
  background-color: #1d2236;
  color: #d1d1d1;
  border: 1px solid #1d2236;
  text-decoration: none;
  width: 100%;
  padding: 2%;
  font-size: 1rem;
  font-weight: bold;
}

.title {
  font-size: 1rem;
  font-weight: bold;
  padding-bottom: 0.5rem;
}

.bot-details-row {
  background-color: rgba(102, 179, 255, 0);
}
.bot-details-row:hover {
  background-color: rgba(102, 179, 255, 0.02);
}

.form-control {
  background-color: hsl(210, 100%, 7%) !important;
  color: rgb(158, 158, 158) !important;
  border: 1px solid gray !important;
}
.form-control:focus {
  border-color: black !important;
}
.form-control:disabled {
  color: gray !important;
}

.equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

.sim-btn {
  background-color: rgba(102, 179, 255, 0.1);
  color: #bfbfbf;
  cursor: pointer;
}

.sim-btn:hover {
  background-color: rgba(102, 179, 255, 0.3);
  color: #bfbfbf;
  cursor: pointer;
}

.grid-item {
  color: #8c8c8c;
}
.dropdown-item {
  color: #a7a6a6 !important;
}
.dropdown-item:hover {
  background-color: #01203d !important;
  color: #c2c0c0 !important;
}

.nav-logout {
  background: transparent;
}
.nav-logout:hover {
  background: rgb(255, 51, 51, 0.2);
}
.nav-myAccount {
  background: transparent;
}
.nav-myAccount:hover {
  background: rgb(38, 38, 38, 0.5);
}

/* Tooltip */
.my-tooltip {
  position: relative;
  /* display: inline-block; */
}

/* Tooltip text */
.my-tooltip .tooltiptext {
  visibility: hidden;
  width: 11rem;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  top: -5px;
  right: 105%;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 100000;
}

/* Show the tooltip text when you mouse over the tooltip container */
.my-tooltip:hover .tooltiptext {
  visibility: visible;
}
.my-tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;

  border-right: 3px solid #ffc107;
}

.componentWrapper .header {
  position: absolute;
  margin-top: -1.15rem;
  margin-left: 10px;
  color: #000000;
  background: rgb(83, 83, 83);
  border-radius: 10px;
  padding: 2px 10px;
  font-size: 0.7rem;
}

/* Tooltip text for create bot page */
.tooltipMy .tooltiptextmy {
  visibility: hidden;
  width: 25rem;
  background-color: #424242;
  color: #fff;
  /* text-align: "left"; */
  padding: 5px 0;
  border-radius: 6px;
  top: -10px;
  left: 50%;
  padding: 10px;
  /* word-wrap: break-word; */
  text-align: justify;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  font-weight: normal;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipMy:hover .tooltiptextmy {
  visibility: visible;
}

.pointer {
  cursor: pointer;
}

.deposit-btn {
  background-color: #02488f;
  color: #d8d8d8;
  border: none;
}
.deposit-btn:hover {
  background-color: #0567ca;
  color: white;
  border: none;
}

.ordersDetailsButton {
  background-color: #353535;
  border-radius: 3px;
  border: none;
  margin: 1px;
  padding: 0.2rem;
}

.ordersDetailsButton:hover {
  background-color: #242424;
}

.ordersDetailsButton:focus {
  background-color: #242424;
  font-weight: bold;
}

.article p {
  font-size: 1rem;
  padding-left: 5%;
  color: #888888;
}

.article h4 {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.article h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.article-card-body {
  color: #a5a5a7;
}
.article-card {
  background-color: rgba(102, 179, 255, 0.15);
}
.article-card:hover {
  background-color: rgba(102, 179, 255, 0.2);
}

/* toggole */
.content {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  /* width: 70%; */
  transform: translateY(5%);
}

.toggle-label,
.toggle {
  height: 1.5rem;
  border-radius: 50px;
}
.toggle-label {
  width: 100%;
  background-color: #001224;
  border-radius: 100px;
  position: relative;
  cursor: pointer;
}
.toggle {
  position: absolute;
  width: 50%;
  background-color: #02488f;
  box-shadow: 0 2px 15px rgb(0, 0, 0, 1);
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0.7;
}
.names {
  font-weight: bolder;
  color: #ffffff;
  width: 100%;
  /* text-align: center; */
  margin-top: 0.18rem;
  position: absolute;
  display: flex;
  justify-content: space-around;
  /* user-select: none; */
}
.dark {
  opacity: 0.5;
}

/* -------- Switch Styles ------------*/
#publicPrivateToggle {
  display: none;
}
/* Toggle */
#publicPrivateToggle:checked + .app .toggle {
  transform: translateX(100%);
  background-color: #02488f;
  color: #ffffff;
  opacity: 1;
}
#publicPrivateToggle:checked + .app .dark {
  opacity: 1;
}
#publicPrivateToggle:checked + .app .light {
  opacity: 0.5;
}

.learnMoreButton {
  color: #888888;
}
.learnMoreButton:hover {
  color: #bfbfbf;
  font-weight: bold;
  cursor: pointer;
}

.binance-post table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 80%;
  margin: 1rem auto 1rem auto;
}

.binance-post td,
th {
  border: 1px solid #464545;
  text-align: center;
  padding: 8px;
}

.rounded-vlg {
  border-radius: 2rem;
}

.custom-signal td,
th {
  border: 1px solid #464545;
  text-align: center;
  padding: 8px;
}

.article strong {
  color: #a8a8a8;
}

/* -------- Switch Styles ------------*/
.buttonToggle {
  display: none;
}
/* Toggle */
.buttonToggle:checked + .app .toggle {
  transform: translateX(100%);
  background-color: #02488f;
  color: #ffffff;
  opacity: 1;
}
.buttonToggle:checked + .app .dark {
  opacity: 1;
}
.buttonToggle:checked + .app .light {
  opacity: 0.5;
}

.copy-btn {
  color: gray;
  background-color: #001224;
  padding: 0.5rem 0.5rem 0.2rem 1rem;
  /* border-color: #242424;
  border: solid 1px; */
}
.copy-btn:hover {
  color: #e9e9e9;
  cursor: pointer;
}

.iframeVideo {
  height: 0px;
  padding-top: 25px;
  padding-bottom: 56.2%;
  position: relative;
}
.iframeVideo iframe {
  width: 100%;
  height: 100%;
  position: absolute;
}
